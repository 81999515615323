import { Observable } from 'rxjs';
import {
  HttpHeaders,
  HttpClient,
  HttpEvent,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };

  URL = 'https://oim-server-app.herokuapp.com/api/v1';
  // URL = 'http://panelblogccpd.santodomingo.gob.ec/server/api/v1';

  constructor(private httpClient: HttpClient) {}

  concatURL(subURL: string): string {
    return this.URL + subURL;
  }

  makePostRequest(URL: string, data: any, headers: any): Observable<any> {
    return this.httpClient.post(URL, data, headers);
  }

  makeGetRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.httpClient.get(URL, headers);
  }

  makeDelRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.httpClient.delete(URL, headers);
  }

  makePutRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.httpClient.put(URL, data, headers);
  }

  upload(file: File): Observable<HttpEvent<any>> {
    const URL = this.concatURL(`/files`);
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', URL, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.httpClient.request(req);
  }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text;
  }
}
